const blogsData = [
    {
        title: "The Future of Real Estate Investment",
        description: "Trends, Tips & Insights into the Property Market for 2025.",
        content: `The real estate market is evolving rapidly. Smart homes, sustainable architecture, 
                  and rising suburban living are some key trends to watch. Here’s why you should invest in 
                  energy-efficient properties now!`,
        image: "https://images.pexels.com/photos/1396122/pexels-photo-1396122.jpeg?cs=srgb&dl=pexels-binyaminmellish-1396122.jpg&fm=jpg",
        date: "February 10, 2025",
        author: "Alex Johnson",
        // category: "Real Estate",
        // tags: ["Investment", "Market Trends", "Smart Homes"]
    },
    {
        title: "Smart Home Technology: A Game-Changer",
        description: "Explore the latest smart home innovations and how they impact real estate.",
        content: `From AI-powered security systems to energy-efficient appliances, smart homes 
                  are revolutionizing the property market. Learn why these features are becoming 
                  a must-have for buyers.`,
        image: "https://architectgpt.s3.us-east-2.amazonaws.com/home39.jpg",
        date: "January 25, 2025",
        author: "Sarah Lee",
        // category: "Technology",
        // tags: ["Smart Homes", "AI", "Automation"]
    },
    {
        title: "Smart Home Technology: A Game-Changer",
        description: "Explore the latest smart home innovations and how they impact real estate.",
        content: `From AI-powered security systems to energy-efficient appliances, smart homes 
                  are revolutionizing the property market. Learn why these features are becoming 
                  a must-have for buyers.`,
        image: "https://media.istockphoto.com/id/178559422/photo/american-craftsman-house.jpg?s=612x612&w=0&k=20&c=YaraczjrrgG46XTLgGsho3X5QSkA0MAgekkFZJXfsE4=",
        date: "January 25, 2025",
        author: "Sarah Lee",
        // category: "Technology",
        // tags: ["Smart Homes", "AI", "Automation"]
    },
];

export default blogsData;
