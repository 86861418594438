import React, { useState } from "react";
import { Box, FormControl, TextField, Button } from "@mui/material";

function DetailForm({ onSubmit }) {
  const [formData, setFormData] = useState({
    full_name: "",
    // email: "",
    phone_number: "",
    address: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleRequestForm = (e) => {
    e.preventDefault();
    if (onSubmit) {
      onSubmit(formData); // Pass form data to parent component
    }
  };

  return (
    <Box sx={{ padding: "20px", maxWidth: "500px", margin: "auto" }}>
      <form onSubmit={handleRequestForm}>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <TextField
            name="full_name"
            label="Full Name"
            variant="outlined"
            size="small"
            value={formData.fullName}
            onChange={handleChange}
            required
          />
        </FormControl>
        {/* <FormControl fullWidth sx={{ mb: 2 }}>
          <TextField
            name="email"
            type="email"
            label="Email"
            variant="outlined"
            size="small"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </FormControl> */}
        <FormControl fullWidth sx={{ mb: 2 }}>
          <TextField
            name="phone_number"
            type="tel"
            label="Phone Number"
            variant="outlined"
            size="small"
            value={formData.phoneNumber}
            onChange={handleChange}
            required
          />
        </FormControl>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <TextField
            name="address"
            label="Address"
            variant="outlined"
            size="small"
            value={formData.address}
            onChange={handleChange}
            required
          />
        </FormControl>
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <Button type="submit" variant="contained" sx={{ color: "white" }}>
            Submit
          </Button>
        </Box>
      </form>
    </Box>
  );
}

export default DetailForm;
