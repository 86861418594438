import React ,{useState} from 'react';
import { Container,Typography, Box } from "@mui/material";
import ServicesListing from './services.cards.listing';
import ServiceButton from './service_apply.button';
import RequestForm from '../requestForm/requestForm';

const customCss = {
    color:"black",
    border: "1px solid #ffa500",
    mt:"40px",
}
const Services = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [phone, setPhone] = useState("");
    const [budget, setBudget] = useState("");
    const [propertyType, setPropertyType] = useState("");
    const [message, setMessage] = useState("");
    const [openForm, setOpenForm] = useState(false);    
    const handleOpenForm=()=>setOpenForm(true);
    const handleCloseForm=()=>setOpenForm(false);
    // const handleFormSubmit=(formData)=>{
    //     console.log("form data",formData);
    //     setOpenForm(false);
    // }

  return (
    <Container
        maxWidth={false}
        sx={{
            backgroundColor:"primary.fifth",
            padding: "0"
        }}
    >
        <Container
            sx={{
                display: "flex",
                flexDirection: {xs:"column"},
                justifyContent: "space-between",
                alignItems: "center",
                height: 'auto',
                // backgroundColor: "red",
                // border: "1px solid black",
                padding:{xs:"30px 10px",sm:"40px 0px",lg:"60px 0px"}
            }}
        >
            <Box
                sx={{
                width:{xs:"100%"},
                }}
            >
                <Typography variant="h5" fontWeight="700" gutterBottom textAlign="center"
                    sx={{
                        // backgroundColor:"yellow",
                        fontSize: {xs:"24px",sm:"28px",md:"38px",lg:"48px"},
                        color: "rgba(0,0,0,1)",
                        textTransform: "uppercase"
                        //   mt:{xs:"12px",sm:"20px"},
                    }}
                >
                    Services
                </Typography>
            </Box>
            <ServicesListing />
            <ServiceButton customCss={customCss} onClick={handleOpenForm}/>
        </Container>
        <RequestForm
                open={openForm}
                onClose={handleCloseForm}
                name={name}
                setName={setName}
                email={email}
                setEmail={setEmail}
                address={address}
                setAddress={setAddress}
                phone={phone}
                setPhone={setPhone}
                budget={budget}
                setBudget={setBudget}
                propertyType={propertyType}
                setPropertyType={setPropertyType}
                message={message}
                setMessage={setMessage}
            />
    </Container>
  );
};

export default Services;