import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const contactDataSet= [
    {
        id:1,
        title:'Contact BYD',
        icon:CallIcon,
        detail:"+977-9812345678, +977-10-1234567"
    },
    {
        id:2,
        title:'Mail BYD',
        icon:EmailIcon,
        detail:"byd12@gmail.com"
    },
    {
        id:3,
        title:'Address',
        icon:LocationOnIcon,
        detail:"M88M+C38,Lalitpur 44700"
    },
    {
        id:4,
        title:'Opening Hours',
        icon:AccessTimeIcon,
        detail:"10:00 AM in the morning to 10:00 PM at night"
    }
]
export default contactDataSet;