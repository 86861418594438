import React, { useState, useEffect } from 'react';
import { Container, Fade, Grow, Typography,Button, Box } from "@mui/material";
import StrokeImage from '../../../assets/images/prop4.jpg';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const HeroSection = () => {

  const [scrollY, setScrollY] = useState(0);
  const text = "B.Y.D Real Estate and Construction P.V.T".split("");

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const backgroundPositionY = `${-scrollY / 10 }px`;

  return (
    <Container
        maxWidth={false}
        sx={{
            // backgroundColor:"primary.fifth",
            padding: "0",
            // backgroundColor:"red"
        }}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: {xs:"column-reverse",sm:"row"},
          justifyContent: "space-between",
          alignItems: "center",
          height: 'auto',
          // backgroundColor: "green",
          // border: "1px solid black",
          padding:{xs:"30px 10px",sm:"50px 0px",lg:"70px 0px"}
        }}
      >
        <Box
          sx={{
            width:{xs:"100%"},
          }}
        >
          <Fade in={true} timeout={5000}>
            <Typography variant="h6" fontWeight="700" textAlign="center"
            sx={{
                fontSize: {xs:"16px",sm:"22px",md:"28px"},
                color: "#000000",
                mt:"20px",
                fontFamily: "cursive"
            }}>
                Feel Free
            </Typography>
          </Fade>
          <Grow in={true} timeout={8000}>
            <Typography variant="h5" fontWeight="700" gutterBottom textAlign="center"
                sx={{
                    fontSize: {xs:"20px",sm:"30px",md:"38px",lg:"48px"},
                    color: "rgba(0,0,0,1)",
                    mt:{xs:"12px",sm:"20px"},
                    // WebkitTextStrokeWidth: "1px solid red",
                  backgroundImage : `url(${StrokeImage})`,
                  WebkitBackgroundClip: "text",
                  backgroundSize:"contain",
                  backgroundPosition: "center",
                  WebkitTextFillColor: "transparent",
                  textTransform: "uppercase"
                }}
                style={{backgroundPositionY}}
            >
                {/* B.Y.D Real Estate and Construction P.V.T */}
                {
                text.map((el, i) => (
                  <motion.span
                  initial={{ opacity: 0, color: "#ffffff" }} 
                  animate={{ opacity: 1, color: "#fbaf1a" }} 
                  transition={{
                    duration: 0.5,
                    delay: i/7.5,
                  }}
                  key={i}
                  >{el}{""}</motion.span>
                ))
              }
            </Typography>
          </Grow>
          <Grow in={true} timeout={8000}>
            <Typography variant="h5" fontWeight="500" 
            // color='textSecondary'
            textAlign="center"
                sx={{
                    fontSize: {xs:"16px",sm:"22px",md:"28px"},
                    textTransform:"capitalize",
                    fontFamily: "cursive",
                    mt: "20px",
                }}
            >
                Would You Like to Build your dream ? 
            </Typography>
          </Grow>
          <Box
            sx={{
              // backgroundColor:"red",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt:{xs:"12px",sm:"20px"}
            }}
          >
            <Link to="/contactUs">
            <Button
              variant="outlined"
              sx={{
                  // backgroundColor: "#000000",
                  color: "rgba(0,0,0,0.7)",
                  fontSize: {xs:"14px",md:"20px"},
                  fontWeight: "400",
                  textTransform: "capitalize",
                  letterSpacing: "1px",
                  borderRadius: "0px 5px 0px 5px",
                  padding:"0px 8px",
                  border: "1px solid primary.third",
                  '&:hover':{
                      // backgroundColor:"primary.second",
                      // boxShadow: "none"
                  }
              }}
            >
              Contact Us
            </Button>
            </Link>
          </Box>
        </Box>
      </Container>
    </Container>
  );
};

export default HeroSection;