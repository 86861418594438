import * as React from 'react';
import Card from '@mui/material/Card';
import { CardContent, CardMedia, Typography, Container, Box } from '@mui/material';

const realEstateData = [
  {
    image: "https://wallpapers.com/images/featured/samantha-hd-3nzxx6n7zr2i7jsc.jpg",
    name: "ABC",
    post: "Manager"
  },
  {
    image: "https://www.koimoi.com/wp-content/new-galleries/2022/07/when-salman-khan-offered-shilajit-something-stronger-than-vagra-to-a-journalist-001.jpg",
    name: "Example",
    post: "AnyPost"
  },
  {
    image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQIT1eC_PM6J3O4QsSoZO0Gw_tG74XCgMb4rg&s",
    name: "Test",
    post: "TestPost"
  }
];

export default function TeamMemberCard() {
  return (
    <Container maxWidth="xl"> {/* Ensure more width */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          gap: 2,
          padding: "20px 0",
          alignItems: "stretch",
          width: "100%", // Ensure full width
        }}
      >
        {realEstateData.map((item, index) => (
          <Card key={index} sx={{ minWidth: "280px", maxWidth: "320px", flex: "1 1 auto" }}>
            <CardMedia sx={{ height: 140 }} image={item.image} title={item.name} />
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                fontFamily="Roboto-Serif"
                component="div"
                sx={{
                  fontSize: { xs: "16px", sm: "22px" },
                  fontWeight: "400",
                  marginBottom: 0
                }}
              >
                {item.name}
              </Typography>
              <Typography
                variant="subtitle2"
                fontFamily="Roboto-Serif"
                sx={{
                  fontSize: { xs: "12px", sm: "14px" },
                  fontWeight: "500",
                  fontStyle: "italic",
                  color: "rgba(0,0,0,0.6)",
                  marginBottom: "8px",
                }}
              >
                {item.post}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Box>
    </Container>
  );
}
