import React from "react";
import { Card, CardMedia, CardContent, Typography, Button } from "@mui/material";

const BlogCard = ({ blog, onClick }) => {
  return (
    <Card sx={{ boxShadow: 3, borderRadius: 3,  height: "100%", display: "flex", flexDirection: "column" }}>
      <CardMedia component="img" height="250" image={blog.image} alt={blog.title} />
      <CardContent sx={{ flexGrow: 1 ,}}>
        <Typography variant="h6" fontWeight="300" gutterBottom 
        sx={{
            fontSize: { xs: "10px", sm: "18px", md: "20px" }, 
            padding: { xs: "4px", sm: "8px", md: "12px" }, 
           
          }}
        >  
          {blog.title}
        </Typography>
        <Typography variant="body2" color="textSecondary" gutterBottom
            
        >
          {blog.description}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          {blog.date} | By {blog.author}
        </Typography>
      </CardContent>
      <Button sx={{ m: 1 }} variant="contained" color="primary" onClick={onClick}>
        Read More
      </Button>
    </Card>
  );
};

export default BlogCard;
