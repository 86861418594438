import ApartmentIcon from '@mui/icons-material/Apartment';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import PeopleIcon from '@mui/icons-material/People';
const aboutDataset= [
    {
        id:1,
        title:'Service',
        icon:ApartmentIcon,
        description:"Lorem ipsum dolor sit amet consectetur adipisicing elit"
    },
    {
        id:2,
        title:'Vision',
        icon:RemoveRedEyeIcon,
        description:"Lorem ipsum dolor sit amet consectetur adipisicing elit"
    },
    {
        id:3,
        title:'Mission',
        icon:ConfirmationNumberIcon,
        description:"Lorem ipsum dolor sit amet consectetur adipisicing elit"
    },
    {
        id:4,
        title:'Project',
        icon:MapsHomeWorkIcon,
        description:"Lorem ipsum dolor sit amet consectetur adipisicing elit"
    },
    {
        id:5,
        title:'Community',
        icon:PeopleIcon,
        description:"Lorem ipsum dolor sit amet consectetur adipisicing elit"
    },
    // {
    //     id:6,
    //     title:'Mission',
    //     icon:AutoAwesomeIcon,
    //     description:"Lorem ipsum dolor sit amet consectetur adipisicing elit"
    // },
]
export default aboutDataset;