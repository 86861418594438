import React from "react";
import { Link, useLocation } from 'react-router-dom';
import { Box, Container, Button } from "@mui/material";
import RightContainer from "./rightContainer";
import LeftContainer from "./leftContainer";

const AboutUs = () => {
  const location = useLocation();

  return (
    <Container
      maxWidth={false}
      sx={{
        backgroundColor: "primary.fifth",
        padding: "0",
      }}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: { xs: "column" },
          justifyContent: "center",
          alignItems: "center",
          height: "auto",
          // backgroundColor:"red",
          padding: { xs: "10px 10px", sm: "30px 10px", lg: "30px 10px 20px 10px" },
        }}
      >
        <Box
          sx={{
            width: { xs: "100%" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: { xs: "column", md: "row" },
              gap: "3rem",
            }}
          >
            {/* Left Container */}
            <LeftContainer />
            {/* Right Container */}
            <RightContainer />
          </Box>
          
          {/* Conditionally render the button */}
          {location.pathname !== "/aboutUs" && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Link to="/aboutUs">
                <Button
                  variant="outlined"
                  sx={{
                    color: "black",
                    borderColor: "secondary.main",
                    borderRadius: "0px 10px 0px 10px",
                    padding: "2px 10px",
                    "&:hover": {
                      borderColor: "secondary.main",
                    },
                  }}
                >
                  About Us
                </Button>
              </Link>
            </Box>
          )}
        </Box>
      </Container>
    </Container>
  );
};

export default AboutUs;
