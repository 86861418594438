import React, { useState } from "react";
import ContactWay from "./contactWay";
import contactData from "./contactDataSet";
import { Grid, Box, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";
import QueryButton from "./clientQueryButton";
import ClientQueryForm from "./clientQueryForm"; // Import the ClientQueryForm

const customCss = {
  color: "black",
  border: "1px solid #ffa500",
  mt: "40px",
};

const CustomDivider = styled(Divider)(({ theme }) => ({
  width: "30%",
  margin: "auto",
  "&::before, &::after": {
    borderColor: "#ba8f41",
    borderWidth: 2,
  },
  [theme.breakpoints.down("md")]: {
    width: "50%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "70%",
  },
}));

function ContactForm() {
  const [openDialog, setOpenDialog] = useState(false); // State to control dialog visibility

  const handleQueryButtonClick = () => {
    setOpenDialog(true); // Open the dialog when button is clicked
  };

  const handleCloseDialog = () => {
    setOpenDialog(false); // Close the dialog
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "10px 0 20px 0",
        }}
      >
        <CustomDivider
          sx={{
            marginTop: { xs: "5px", sm: "8px", md: "10px", lg: "15px", xl: "20px" },
            fontSize: { xs: "22px", sm: "26px", md: "36px", lg: "46px" },
            fontWeight: "700",
          }}
        >
          Contact Us
        </CustomDivider>
      </Box>

      <Box sx={{ marginBottom: "6px" }}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={1.3} justifyContent="center" alignItems="center">
            {contactData.map((item) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
                <ContactWay content={item} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>

      {/* Query Button */}
      <QueryButton customCss={customCss} onClick={handleQueryButtonClick} />

      {/* Client Query Form Dialog */}
      <ClientQueryForm open={openDialog} onClose={handleCloseDialog} />

      <Box
        sx={{
          margin: "10px",
          height: { xs: "250px", sm: "300px", md: "400px", lg: "450px", xl: "500px" },
          overflow: "hidden",
          borderRadius: "8px",
          boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
        }}
        component="div"
      >
        <iframe
          title="Google Maps showing BBG Restaurant location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3533.6581515619773!2d85.33012167453415!3d27.666046327313552!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb1905141b0983%3A0xbd5fda8d1783fc09!2sBBG%20Restaurant!5e0!3m2!1sen!2snp!4v1719458711525!5m2!1sen!2snp"
          width="100%"
          height="100%"
          style={{ border: "0" }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </Box>
    </>
  );
}

export default ContactForm;
