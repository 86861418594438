import React from 'react';
import { Container, Box, useMediaQuery, useTheme, Typography, Grid } from "@mui/material";
import ProjectsImage from '../../../assets/images/more/projects.jpg';
import ProjectsImage1 from '../../../assets/images/more/pp.jpg';
import ProjectsData from '../../projects/projects.data';

const ProjectsPageComponent = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Container maxWidth={false} disableGutters sx={{ backgroundColor: "primary.fifth", padding: 0 }}>
            {/* Hero Section */}
            <Box
                sx={{
                    height: { xs: "300px", sm: "400px", md: "600px" },
                    width: "100%",
                    backgroundImage: isSmallScreen ? `url(${ProjectsImage})` : `url(${ProjectsImage1})`,
                    backgroundPosition: isSmallScreen ? "top" : "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    boxShadow: { xs: "inset 0px -80px 20px rgba(0,0,0,0.5)", sm: "inset 0px -200px 20px rgba(0,0,0,0.5)" },
                }}
            />

            {/* Title Section */}
            <Container
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    height: 'auto',
                    padding: { xs: "10px 10px 30px 10px", sm: "20px 10px 40px 10px", lg: "20px 0px 60px 0px" },
                    position: "relative",
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        position: "absolute",
                        top: { xs: "-60px", sm: "-120px", md: "-180px" },
                    }}
                >
                    <Typography
                        variant="h5"
                        fontWeight="700"
                        textAlign="center"
                        sx={{
                            fontSize: { xs: "24px", sm: "28px", md: "38px", lg: "48px" },
                            color: "#FFFFFF",
                            textTransform: "uppercase",
                        }}
                    >
                        Projects
                    </Typography>
                    <Typography
                        variant="h5"
                        fontWeight="500"
                        gutterBottom
                        textAlign="center"
                        fontFamily="cursive"
                        sx={{
                            fontSize: { xs: "14px", sm: "18px", md: "24px" },
                            color: "#FFFFFF",
                            textTransform: "capitalize",
                        }}
                    >
                        Proud to showcase our dedication.
                    </Typography>
                </Box>

                {/* Projects List */}
                <Box sx={{ flexGrow: 1, p: 2 }}>
                    {ProjectsData.map((project) => (
                        <Grid container spacing={2} alignItems="center" key={project.id} sx={{ mb: 4 }}>
                            {/* Left Side - Project Image */}
                            <Grid item xs={12} md={6} lg={6}>
                                <Box
                                    component="img"
                                    src={project.projectsImage}
                                    alt={project.projectsTitle}
                                    sx={{ width: '100%', height: 'auto', borderRadius: 2 }}
                                />
                            </Grid>

                            {/* Right Side - Project Details */}
                            <Grid item xs={12} md={6} lg={6}>
                                <Typography variant="h5" fontWeight="600" gutterBottom>
                                    {project.projectsTitle}
                                </Typography>
                                <Typography variant="subtitle1" color="textSecondary">
                                    <b>Status:</b> {project.projectsStatus.charAt(0).toUpperCase() + project.projectsStatus.slice(1)}
                                </Typography>
                                <Typography variant="subtitle1" color="textSecondary">
                                    <b>Location:</b> {project.projectsLocation}
                                </Typography>
                                <Typography variant="subtitle1" color="textSecondary">
                                    <b>Started On:</b> {project.startedOn}
                                </Typography>
                                {project.completedOn && (
                                    <Typography variant="subtitle1" color="textSecondary">
                                        <b>Completed On:</b> {project.completedOn}
                                    </Typography>
                                )}

                                {/* Description */}
                                
                            </Grid>
                            <Box sx={{padding:{xs:"0 10px",sm:"0 18px",md:"0 20px"}}}>
                            <Typography variant="body1" sx={{ mt: 2, textAlign: 'justify' }}>
                        {project.description}
                    </Typography>
                    </Box>
                        </Grid>
                        
                    ))}
                </Box>
            </Container>
        </Container>
    );
};

export default ProjectsPageComponent;
