import React,{useState,useCallback} from "react";
import { Box, Typography, IconButton, Container ,Dialog} from "@mui/material";
import './footer.css';
import { Link , useNavigate} from "react-router-dom";
// import navItems from "../../components/utils/nav_items";
import socialMediaIcons from "../../components/utils/social_medias";
import Logo from '../../assets/byd_logo.png';
import { HashLink } from "react-router-hash-link";
import FormContainer from "../../components/request_properties/form_container"; 


const navItems = [
  {id: 1, name: "Home", to: "/",}, 
  {id: 2, name: "Services", to: "/services", },
  {id: 3, name: "Projects", to: "/projects", },
  {id: 4, name: "Gallery", to: "/gallery",}, 
  {id: 5, name: "Blogs", to: "/#blogs",}, 
  {id: 6, name: "AboutUs", to: "/aboutUs",}, 
  {id: 7, name: "ContactUs", to: "/contactUs",}, 
  {id: 8, name: "Request Property", to: "/properties",
},
];

function FooterBar() {
  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false); 
  const [activeItem, setActiveItem] = useState(""); // Track active item
  const date = new Date();
  const navigate=useNavigate();
  
  
  const year = date.getFullYear();

  const handleNavItemClick = useCallback(
      (to) => {
        setActiveItem(to);
        navigate(to);
        if (isMobileDrawerOpen) setIsMobileDrawerOpen(false);
      },
      [navigate, isMobileDrawerOpen]
    );

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  // Close dialog
  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const renderItems = (
    <>
      {navItems?.map((item, index) => (
        <Typography
          key={index}
          variant="h6"
          fontWeight="500"
          sx={{
            color: activeItem === item.name ? "#1976d2" : "#000000", // Highlight color for active item
            // margin: "8px 0", // Space between items for better touch targeting
            fontSize: "16px",
            cursor: "pointer",
            // padding: "6px 4px", // Padding around text for easier tap on mobile
            transition: 'background-color 0.3s ease',
            // '&:hover': {
            //   backgroundColor: '#eeeeee', // Light hover effect
            // },
            fontWeight: activeItem === item.name ? "600" : "500", // Optional: make the active item bold
            borderBottom: activeItem === item.name ? "2px solid #1976d2" : "none", // Optional: Add an underline for active item
          }}
          onClick={() => {
            if (item.name === "Request Property") {
              handleDialogOpen();
            } else {
              handleNavItemClick(item.to); // Navigate to the link and set active item
            }
          }}
        >
          {item.name === "Request Property" ? (
            <span>{item.name}</span>
          ) : (
            <HashLink
              style={{ backgroundColor: "transparent", color: "#000000" }}
              to={item.to}
            >
              {item.name}
            </HashLink>
          )}
        </Typography>
      ))}
    </>
  );

  return (
    <Container
      sx={{
        // backgroundColor: "primary.main",
        display: "flex",
        flexDirection: "column",
        height: "auto",
        alignItems: "center",
        padding: { xs: "20px", sm: "80px 60px 60px" }
      }}
    >
      <Box
        sx={{
          // backgroundColor: "red",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: "center",
          mb: { xs: "20px", sm: "30px" },
        }}
        >
        {/* Logo section */}
        <Box
          sx={{
            // border: "1px solid black",
            // borderRadius: "50%",
            height: "70px",
            width: "80px",
            // backgroundColor: "skyblue",
            mb:{xs:"20px",sm:"0px"},
            backgroundImage: `url(${Logo})`,
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat"
          }}
        >
        </Box>

        {/* Quick links */}
        <Box
          sx={{
            // backgroundColor: "yellow",
            display: "flex",
            flexDirection: {xs:"column",md:"row"},
            fontSize:{xs:"12px",sm:"16px"},
            gap: {xs:"8px",md:"20px"},
            color:"#8f6B29"
          }}
        >
          {renderItems}
          {/* {
            navItems.map((data,index)=>(
            
              <Typography key={index} textAlign="center" fontFamily="fantasy"
                sx={{
                  letterSpacing: "2px"
                }}
              >{data.navItem}</Typography>
            )
            )
          } */}
        </Box>

        {/* Social media icons */}
        <Box
          sx={{
            mt:{xs:"20px",sm:"0px"}
          }}
        >
          {socialMediaIcons.map((icon, index) => (
            <IconButton key={index} aria-label={icon.label}>
              <icon.component />
            </IconButton>
          ))}
        </Box>
      </Box>

      {/* Horizontal line */}
      <Box
        sx={{
          height: "1px",
          width: "80%",
          backgroundColor: "#000"
        }}
      ></Box>

      {/* Copyright or other footer content */}
      <Box mt={2}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Typography variant="body2" color="textSecondary">
          © {year} B.Y.D Developer. All rights reserved.
        </Typography>
        <Link to="https://blackmoon.com.np/" target="_blank" >
          <Typography variant="body2" color="textSecondary" textAlign="center">
              Developed By
          </Typography>
        </Link>
      </Box>

      <Dialog open={isDialogOpen} onClose={handleDialogClose} maxWidth="md" fullWidth={false}>
        <FormContainer onSubmit={handleDialogClose} />
      </Dialog>
    </Container>
  );
}

export default FooterBar;