import React from "react";
import AboutUsComponent from './about.components';
import { Box, Container, Typography } from "@mui/material";
import DiscountOfferSection from './discountOfferSection';
import Message from './message';
import TeamMembers from './teamMember';
import aboutDataSet from "./aboutDataSet"; // Import the dataset

const AboutUs = () => {
  return (
    <Container
      maxWidth={false}
      sx={{
        backgroundColor: "primary.fifth",
        padding: "0",
      }}
    >
      <Container
        sx={{
          // backgroundColor:"red",
          display: "flex",
          flexDirection: { xs: "column" },
          justifyContent: "center",
          alignItems: "center",
          height: "auto",
          padding: { xs: "30px 10px", sm: "40px 0px", lg: "60px 0px" },
        }}
      >
        <AboutUsComponent/>

    <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}> 
    <Message />
  </Box>

        <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        justifyContent: "center",
        gap: "10px", // Add some spacing between the boxes
        // backgroundColor:"blue",
        
      }}
    >
      {/* <Box> */}
          <Typography sx={{textAlign:"center",fontSize: {xs:"16px",sm:"22px"},
            fontWeight: "600" ,padding:"0px 0px 20px 0px"}}>Our Core Values</Typography>
      {/* </Box> */}
      <Box sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
        gap: "20px", // Add some spacing between the boxes
        // backgroundColor:"yellow"
      }}>
      {aboutDataSet.map((data) => (
        <DiscountOfferSection key={data.id} content={data} />
      ))}
      </Box>
    </Box>
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems:"center",
        gap: "10px", // Add some spacing between the boxes
        // backgroundColor:"yellow",
        margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" }
        
      }}
    >
      <Typography sx={{
      textAlign:"center",
      fontSize: {xs:"16px",sm:"22px"},
      fontWeight: "600",
      // padding:"0px 0px 10px 0px"
      }}>
        Our Team Members
      </Typography>
    <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
    <TeamMembers />
  </Box>
  </Box>
    </Container>
    </Container>
  );
};
export default AboutUs;