import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { keyframes } from "@emotion/react";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

function DiscountOfferSection({ content }) {
  console.log("content", content);
  const [hover, setHover] = useState(false);
  const IconComponent = content.icon; // Destructure the icon from the content

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        paddingTop: "20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
          width: { xs: "80%", sm: "200px" },
          margin: "0px auto",
          position: "relative",
          borderRadius: "12px",
          height: "auto", // For debugging, set to auto
          transition:
            "background-color 3s ease-in-out, color 3s ease-in-out, height 0.5s ease",
          "&:hover": {
            backgroundColor: "#ba8f41",
            color: "#ffffff",
          },
          zIndex: 1,
        //   border: "1px solid red", // Temporary border for debugging
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onTouchStart={() => setHover(true)}
      >
        {/* Small box with icon */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            top: "-25px",
            border: "2px solid #ffffff",
            width: "50px",
            height: "50px",
            backgroundColor: "#ba8f41",
            position: "absolute",
            borderRadius: "8px",
          }}
        >
          {IconComponent && <IconComponent sx={{ color: "#ffffff" }} />}
        </Box>

        {/* Title */}
        <Typography
          sx={{
            marginTop: "15px",
          }}
        >
          {content.title}
        </Typography>

        {/* Divider Box */}
        <Box
          sx={{
            marginTop: "10px",
            height: "2px",
            width: "50px",
            borderRadius: "1px",
            backgroundColor: "#ba8f41",
          }}
        ></Box>

        {/* Show description on hover */}
        {hover && (
          <Typography
            sx={{
              color: "#ffffff",
              marginTop: "10px",
              textAlign: "center",
              opacity: hover ? 1 : 0,
              transition: hover
                ? "opacity 1s 0.5s, transform 1s 0.5s"
                : "opacity 0.5s, transform 0.5s",
              transform: hover ? "translateY(0)" : "translateY(10px)",
              animation: `${fadeIn} 0.3s`,
            }}
          >
            {content.description}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default DiscountOfferSection;
