import React from 'react'
import { Container } from "@mui/material";
import BlogsPageComponent from '../components/page_components/blogs/blogs.page.component';
import Testimonials from '../components/testimonials/testimonials.components';

const BlogsPage = () => {
    return(
        <Container maxWidth={false}
            disableGutters
            sx={{
                // border: "1px solid red",
                // backgroundColor: "yellow",
                // padding : "0px 10px"
            }}
        >
            <BlogsPageComponent />
            <Testimonials />
        </Container>
    );
}

export default BlogsPage;



//Github

// import React from 'react';
// import { Container, Box, useMediaQuery, useTheme, Typography } from "@mui/material";
// import BlogImage from '../assets/byd_logo.png';
// import BlogImage1 from '../assets/byd_logo.png';
// import BlogsData from '../components/blogs/blogs.Data';
// import BlogDetailComponent from '../components/blogs/blogs.components';
// import BlogButton from '../components/blogs/blogButton';

// const customCss = {
//     color: "#000000",
//     border: "1px solid #ffa500",
//     mt: "40px",
//     backgroundColor: "transparent"
// };
// const customCss1 = {
//     color: "#ffffff",
//     border: "1px solid #ffffff",
//     mt: "20px",
//     backgroundColor: "transparent"
// };

// const BlogsPageComponent = () => {
//     const theme = useTheme();
//     const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

//     return (
//         <Container
//             maxWidth={false}
//             disableGutters
//             sx={{
//                 backgroundColor: "primary.fifth",
//                 padding: "0px",
//             }}
//         >
//             <Box
//                 sx={{
//                     height: { xs: "300px", sm: "400px", md: "600px" },
//                     width: "100%",
//                     backgroundImage: isSmallScreen ? `url(${BlogImage})` : `url(${BlogImage1})`,
//                     backgroundPosition: isSmallScreen ? "top" : "center",
//                     backgroundSize: "cover",
//                     backgroundRepeat: "no-repeat",
//                     boxShadow: { xs: "inset 0px -80px 20px rgba(0,0,0,0.5)", sm: "inset 0px -200px 20px rgba(0,0,0,0.5)" },
//                 }}
//             ></Box>
//             <Container
//                 sx={{
//                     display: "flex",
//                     flexDirection: { xs: "column" },
//                     justifyContent: "space-between",
//                     alignItems: "center",
//                     height: 'auto',
//                     padding: { xs: "10px 10px 30px 10px", sm: "20px 10px 40px 10px", lg: "20px 0px 60px 0px" },
//                     position: "relative",
//                 }}
//             >
//                 <Box
//                     sx={{
//                         width: { xs: "100%" },
//                         position: "absolute",
//                         top: { xs: "-60px", sm: "-120px", md: "-180px" },
//                     }}
//                 >
//                     <Typography
//                         variant="h5"
//                         fontWeight="700"
//                         textAlign="center"
//                         sx={{
//                             fontSize: { xs: "24px", sm: "28px", md: "38px", lg: "48px" },
//                             color: "#FFFFFF",
//                             textTransform: "uppercase",
//                         }}
//                     >
//                         Blogs
//                     </Typography>
//                     <Typography
//                         variant="h5"
//                         fontWeight="500"
//                         gutterBottom
//                         textAlign="center"
//                         fontFamily="cursive"
//                         sx={{
//                             fontSize: { xs: "14px", sm: "18px", md: "24px" },
//                             color: "#FFFFFF",
//                             textTransform: "capitalize",
//                         }}
//                     >
//                         Stay updated with our latest e-commerce trends and insights.
//                     </Typography>
//                     {/* <BlogButton customCss={customCss1} /> */}
//                 </Box>
//                 <Box
//                     sx={{
//                         height: "auto",
//                         width: "100%",
//                         display: "flex",
//                         flexDirection: "column",
//                     }}
//                 >
//                     {BlogsData.map((bData, index) => (
//                         <BlogDetailComponent blogData={bData} key={index} />
//                     ))}
//                 </Box>
//                 <BlogButton customCss={customCss} />
//             </Container>
//         </Container>
//     );
// };

// export default BlogsPageComponent;