import React from "react";
import { Button, Box } from "@mui/material";

const QueryButton = ({ customCss, onClick }) => {
    return (
        <Box
            sx={{
                // backgroundColor:"red",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: { xs: "12px", sm: customCss.mt },
            }}
        >
            <Button
                variant="outlined"
                sx={{
                    color: customCss.color,
                    fontSize: { xs: "14px", md: "20px" },
                    fontWeight: "400",
                    textTransform: "capitalize",
                    letterSpacing: "1px",
                    padding: "0px 14px",
                    border: customCss.border,
                    borderRadius: "0px 5px 0px 5px",
                    backgroundColor: customCss.backgroundColor,
                    "&:hover": {
                        transform: "scale(1.2)",
                        transition: "2s linear",
                        backgroundColor: "rgba(0,0,0,0.3)",
                        color: "#ffffff",
                        border: "none",
                    },
                }}
                onClick={onClick}  // Call onClick passed as prop
            >
                Query
            </Button>
        </Box>
    );
};

export default QueryButton;
