import React ,{useState} from "react";
import { Container, Typography, Button, Box } from "@mui/material";
import PropertyListing from "./properties.cards.listing";
import PropertyFilterMain from "./filter.section/properties.filter.main";
// import TopListing from './topListing/top.listing.components';
// import PremiumListing from './premiumListing/premium.listing.components';
// import FeaturedListing from './featuredListing/featured.listing.components';

const Properties = () => {
  const [showAllListings,setShowAllListings]=useState(false);

  const handleSeeAllClick=()=>{
    setShowAllListings(true);
  };

  const handleShowLessClick=()=>{
    setShowAllListings(false);
  };
  return (
    <Container
      maxWidth={false}
      sx={{
        backgroundColor: "primary.sixth",
        padding: "0",
        position: "relative", // Enable overlay positioning
        // backgroundColor:"blue",
        height:"auto",
      }}
    >
      {/* Overlay Filter Component Positioned Centrally Above Properties Section */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: { xs: "90%", md: "70%" },
          position: "absolute",
          top: {md: "-100px", xs:"-70px"}, // Adjust to place the filter component above Properties
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: 2, // Keep it above the rest of the content
        }}
      >
        <PropertyFilterMain />
      </Box>

      {/* Main Properties Content */}
      <Container
        sx={{
          display: "flex",
          flexDirection: { xs: "column" },
          justifyContent: "space-between",
          alignItems: "center",
          height: "auto",
          padding: { xs: "30px 10px", sm: "40px 0px", lg: "60px 0px" },
          mt: 6, // Add margin to prevent overlay from covering content
        }}
      >
        {/* Section Title */}
        <Box
          sx={{
            width: { xs: "100%" },
          }}
        >
          <Typography
            variant="h5"
            fontWeight="700"
            gutterBottom
            textAlign="center"
            sx={{
              fontSize: { xs: "24px", sm: "28px", md: "38px", lg: "48px" },
              color: "rgba(0,0,0,1)",
              textTransform: "uppercase",
              mt:"3rem"
            }}
          >
            Properties
          </Typography>
        </Box>

        {/* Property Listings */}
        <PropertyListing />

        {/* See All Button */}
        {!showAllListings ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: { xs: "12px", sm: "20px" },
            }}
          >
            <Button
              variant="outlined"
              sx={{
                color: "rgba(0,0,0,0.7)",
                fontSize: { xs: "14px", md: "20px" },
                fontWeight: "400",
                textTransform: "capitalize",
                letterSpacing: "1px",
                padding: "0px 8px",
                border: "none",
                "&:hover": {
                  transform: "scale(1.2)",
                  transition: "2s ease",
                  backgroundColor: "transparent",
                },
              }}
              onClick={handleSeeAllClick} 
            >
              See all
            </Button>
          </Box>
        ) : (
          <>
            {/* <TopListing /> */}
            {/* <PremiumListing /> */}
            {/* <FeaturedListing /> */}

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: { xs: "12px", sm: "20px" },
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  color: "rgba(0,0,0,0.7)",
                  fontSize: { xs: "14px", md: "20px" },
                  fontWeight: "400",
                  textTransform: "capitalize",
                  letterSpacing: "1px",
                  padding: "0px 8px",
                  border: "none",
                  "&:hover": {
                    transform: "scale(1.2)",
                    transition: "2s ease",
                    backgroundColor: "transparent",
                  },
                }}
                onClick={handleShowLessClick} 
              >
                Show less
              </Button>
            </Box>
          </>
        )}
      </Container>
    </Container>
  );
};

export default Properties;