//homepage

import Prop1 from '../../assets/images/prop1.jpg';
import Prop2 from '../../assets/images/prop2.jpg';
import Prop3 from '../../assets/images/prop3.jpg';
const BlogData = [
    {
      id: 1,
      title: "The Future of Real Estate",
      author: "John Doe",
      date: "August 5, 2023",
      excerpt: "Real estate is changing rapidly with the advent of new technologies...",
      image: Prop1
    },
    {
      id: 2,
      title: "Top 10 Real Estate Investment Tips",
      author: "Jane Smith",
      date: "July 22, 2023",
      excerpt: "Looking to invest in real estate? Here are 10 tips that you can't afford to miss...",
      image: Prop2
    },
    {
      id: 3,
      title: "How to Find Your Dream Home",
      author: "Sarah Lee",
      date: "June 15, 2023",
      excerpt: "Finding your dream home can be a daunting process, but with the right strategy...",
      image: Prop3
    }
    // Add more blog entries as needed
  ];
  
  export default BlogData;
  
