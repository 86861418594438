import React from 'react';
import './App.css';
import './global.css';
import RootLayout from './root_layout';
import LandingPage from './pages/landing.page';
import DesPage from './components/propertyDescription/propertyDetail';
import ServicesPage from './pages/services.page';
import ProjectsPage from './pages/projects.page';
import GalleryPage from './pages/gallery.page';

import AboutUsPage from './components/about_us/aboutUsPage';
import ContactUsPage from './components/contact_us/contactLandingPage'


import BlogsPage from './pages/blogs.page';

import { 
  createBrowserRouter, 
  createRoutesFromElements, 
  RouterProvider, 
  Route } from 'react-router-dom';

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route exact path='/' element={<RootLayout />}>
          <Route index element={<LandingPage />} />
          <Route path="/description/:id" element={<DesPage />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/projects" element={<ProjectsPage />} />
          <Route path="/gallery" element={<GalleryPage />} />
          <Route path="/aboutUs" element={<AboutUsPage />} />
          <Route path="/contactUs" element={<ContactUsPage />} />
          <Route path="/blogs" element={<BlogsPage />} />  
          {/* <Route path="/blog/:slug" element={<BlogDetailPage />} /> */}
        </Route>
      </Route>
    )
  );

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;