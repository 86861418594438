import React from "react";
import {Card, CardContent, Typography } from '@mui/material';
import Grid from "@mui/material/Grid2";

const ServicesCard = ({services})=>{
    return(
        <Grid item xs={12} sm={3} md={4}>
              <Card
                sx={{
                    maxWidth:{xs:"340px",md:"260px",lg:"340px"},
                    boxShadow: {sm:"0px 1px 12px 0px rgba(0,0,0,0.3)"},
                    // backgroundColor: "primary.sixth"
                }}
              >
                <CardContent>
                  <Typography variant="h6" fontFamily="Roboto-Serif"
                    sx={{
                        fontSize: {xs:"16px",sm:"22px"},
                        fontWeight: "600"
                    }}
                  >
                        {services.serviceTitle}
                  </Typography>
                  <Typography variant="body2"  fontFamily="AppleMyungjo"
                    sx={{
                        fontSize: {xs:"12px",sm:"14px"},
                        textAlign: "justify",
                        color: "rgba(0,0,0,0.7)",
                    }}
                >
                    {services.serviceDescription.slice(0,340)} ...
                  </Typography>
                </CardContent>
              </Card>
        </Grid>
    )
}
export default ServicesCard;