import React, { useState } from "react";
import axios from "axios";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  TextField,
  IconButton,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

function ClientQueryForm({ open, onClose }) {
  // State variables to handle form data
  const [client_name, setClientName] = useState("");
  const [client_email, setClientEmail] = useState("");
  const [client_mobile, setClientMobile] = useState("");
  const [client_query, setClientQuery] = useState("");
  const [error, setError] = useState("");

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!client_name.trim() || !client_email.trim() || !client_mobile.trim() || !client_query.trim()) {
      setError("All fields are required.");
      return;
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(client_email)) {
      setError("Please enter a valid email address.");
      return;
    }

    if (!/^\d{10}$/.test(client_mobile)) {
      setError("Please enter a valid 10-digit phone number.");
      return;
    }

    setError("");

    const formData = { client_name, client_email, client_mobile, client_query };

    try {
      const response = await axios.post("http://localhost:8001/byd/clientQuery", formData);
      console.log("Form submitted successfully:", response.data);
      alert("Query submitted successfully!");
      
      setClientName("");
      setClientEmail("");
      setClientMobile("");
      setClientQuery("");
      onClose();
    } catch (error) {
      console.error("Error submitting query:", error);
      alert("Failed to submit query. Please try again.");
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "8px 24px 0 24px" }}>
        <DialogTitle sx={{ textAlign: "center", flex: 1 }}>Client Query Form</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: "absolute", right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent>
        <DialogContentText sx={{ textAlign: "center", marginBottom: 2.5 }}>
          Fill in the details below to submit your query.
        </DialogContentText>
        <Box component="form" onSubmit={handleSubmit} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <TextField
            label="Full Name"
            variant="outlined"
            size="small"
            fullWidth
            value={client_name}
            onChange={(e) => setClientName(e.target.value)}
            sx={{ mb: 1.8 }}
            error={!!error && !client_name.trim()}
            helperText={error && !client_name.trim() && "Name is required"}
          />
          <TextField
            label="Email"
            type="email"
            variant="outlined"
            size="small"
            fullWidth
            value={client_email}
            onChange={(e) => setClientEmail(e.target.value)}
            sx={{ mb: 1.8 }}
            error={!!error && !client_email.trim()}
            helperText={error && !client_email.trim() && "Email is required"}
          />
          <TextField
            label="Phone"
            type="tel"
            variant="outlined"
            size="small"
            fullWidth
            value={client_mobile}
            onChange={(e) => setClientMobile(e.target.value)}
            sx={{ mb: 1.8 }}
            error={!!error && !/^\d{10}$/.test(client_mobile)}
            helperText={error && !/^\d{10}$/.test(client_mobile) && "Please enter a valid phone number"}
          />
          <TextField
            label="Your Query"
            variant="outlined"
            size="small"
            fullWidth
            multiline
            rows={4}
            value={client_query}
            onChange={(e) => setClientQuery(e.target.value)}
            sx={{ mb: 2 }}
          />
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ backgroundColor: "secondary.main", color: "white", padding: "10px 20px", borderRadius: "10px" }}
            >
              Submit Query
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default ClientQueryForm;
