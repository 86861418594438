import React from "react";
import { Container, Box, Typography } from "@mui/material";
import BlogList from "./blog_list";
const BlogPage = () => {
  // const theme = useTheme();
  // const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  console.log("check check....");
  return (
    <Container maxWidth={false} disableGutters>
       {/* <HeroSection />  */}
      <Box
        sx={{
          height: { xs: "250px", sm: "350px", md: "500px" },
          width: "100%",
          backgroundImage: "url(https://www.mockofun.com/wp-content/uploads/2024/01/AI-house-generator-2.jpg)",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          position: "relative",
          // backgroundColor:'red',
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "inset 0px -80px 20px rgba(0,0,0,0.5)",
        }}
      >
        <Box sx={{ textAlign: "center", color: "#fff" }}>
          <Typography
            variant="h3"
            fontWeight="bold"
            sx={{ fontSize: { xs: "24px", sm: "32px", md: "48px" },marginTop:'19rem', textTransform: "uppercase" }}
          >
            Real Estate Blogs
          </Typography>
          <Typography variant="h6" fontWeight="300" fontFamily="cursive" sx={{ marginBottom: {xs:'7rem',sm:'3rem'} , fontSize:{xs:"15px", sm:'20px'}}}>
            Discover the latest insights and trends in real estate.
          </Typography>
        </Box>
      </Box>

      <Container sx={{ mt: 5 }}>
        <BlogList />
      </Container>
    </Container>
  );
};

export default BlogPage;