import React, { useState } from "react";
import axios from "axios";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  TextField,
  IconButton,
  Box,
  FormControl,
  Select,MenuItem,InputLabel
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

function RealEstateRequestForm({ open, onClose }) {
  // State variables to handle form data
  const [name, setName] = useState("");
  // const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  // const [budget, setBudget] = useState("");
  const [service, setService] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validate form fields
    // if (!name.trim() || !email.trim() || !phone.trim() || !budget.trim()) {
    //   setError("All fields are required.");
    //   return;
    // }

    if (!/^\d{10}$/.test(phone)) {
      setError("Please enter a valid 10-digit phone number.");
      return;
    }

    // const parsedBudget = parseFloat(budget);
    // if (isNaN(parsedBudget) || parsedBudget <= 0) {
    //   setError("Please enter a valid budget greater than 0.");
    //   return;
    // }

    setError(""); // Clear any previous errors
    console.log("Services",service);

    // Prepare form data
    const formData = {
      name,
      // email,
      address,
      phone,
      service,
      // budget: parsedBudget,
      message,
    };

    try {
      // Send form data to backend using axios
      const response = await axios.post("http://localhost:8001/byd/serviceRequest", formData);
      console.log("Form data:",formData);

      console.log("Form submitted successfully:", response.data);
      alert("Request submitted successfully!");

      // Clear form fields after submission
      setName("");
      // setEmail("");
      setAddress("");
      setPhone("");
      setService("");
      // setBudget("");
      setMessage("");

      // Close the form dialog
      onClose();
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("Failed to submit request. Please try again.");
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "8px 24px 0 24px",
        }}
      >
        <DialogTitle sx={{ textAlign: "center", flex: 1 }}>
          Request for Real Estate Service
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent>
        <DialogContentText sx={{ textAlign: "center", marginBottom: 2.5 }}>
          Fill in the details below to request our real estate services.
        </DialogContentText>
        <Box component="form" onSubmit={handleSubmit} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          {/* Name Input */}
          <TextField
            label="Full Name"
            variant="outlined"
            size="small"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
            sx={{ mb: 1.8 }}
            error={!!error && !name.trim()}
            helperText={error && !name.trim() && "Name is required"}
          />

          {/* Email Input */}
          {/* <TextField
            label="Email"
            type="email"
            variant="outlined"
            size="small"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ mb: 1.8 }}
            error={!!error && !email.trim()}
            helperText={error && !email.trim() && "Email is required"}
          /> */}

          {/* Address Input */}
          <TextField
            label="Address"
            variant="outlined"
            size="small"
            fullWidth
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            sx={{ mb: 1.8 }}
          />

          {/* Phone Input */}
          <TextField
            label="Phone"
            type="tel"
            variant="outlined"
            size="small"
            fullWidth
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            sx={{ mb: 1.8 }}
            error={!!error && !/^\d{10}$/.test(phone)}
            helperText={error && !/^\d{10}$/.test(phone) && "Please enter a valid phone number"}
          />

          {/* Property Type Dropdown */}
          <FormControl size="small" sx={{ mb: 1.8 }} fullWidth>
              <InputLabel>Services</InputLabel>
              <Select name="service" value={service} onChange={(e) => setService(e.target.value)}>
                <MenuItem value="Construction">Construction</MenuItem>
                <MenuItem value="Real Estae">Real Estate</MenuItem>
                <MenuItem value="Architect">Architect</MenuItem>
                <MenuItem value="Renovation">Renovation</MenuItem>
                <MenuItem value="Plumber">Plumber</MenuItem>
                <MenuItem value="Electrician">Electrician</MenuItem>
              </Select>
            </FormControl>

          {/* Budget Input */}
          {/* <TextField
            label="Budget Range"
            variant="outlined"
            size="small"
            fullWidth
            type="number"
            value={budget}
            onChange={(e) => setBudget(e.target.value)}
            sx={{ mb: 1.8 }}
            error={!!error && (isNaN(budget) || parseFloat(budget) <= 0)}
            helperText={error && (isNaN(budget) || parseFloat(budget) <= 0) && "Budget must be a valid number greater than 0"}
          /> */}

          {/* Message Input */}
          <TextField
            label="Message"
            variant="outlined"
            size="small"
            fullWidth
            multiline
            rows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            sx={{ mb: 2 }}
          />

          {/* Submit Button */}
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{
                backgroundColor: "secondary.main",
                color: "white",
                padding: "10px 20px",
                borderRadius: "10px",
              }}
            >
              Submit Request
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default RealEstateRequestForm;
