import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Button,
  Grid,
} from "@mui/material";

function PropertyRequest({ onSubmit }) {
  // Use state to hold form data
  const [formData, setFormData] = useState({
    province: '',
    district: '',
    municipality: '',
    ward: '',
    landmark: '',
    propertyType: '',
    facing: '',
    urgency: '',
    priceFrom: '',
    priceTo: '',
    description: '',
    type: '',
  });

  // Handle change in form fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Pass the form data back to parent component
    onSubmit(formData);
  };

  return (
    <Box sx={{ padding: "10px" }}>
      <form onSubmit={handleSubmit}>
        <Typography variant="h5" marginBottom="12px">
          Location
        </Typography>

        <Box sx={{ display: "grid", gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" }, gap: "12px" }}>
          {["Province", "District", "Municipality", "Ward", "Landmark"].map((label) => (
            <TextField
              key={label}
              name={label.toLowerCase()}
              value={formData[label.toLowerCase()]}
              onChange={handleChange}
              fullWidth
              size="small"
              label={label}
            />
          ))}
        </Box>

        <Typography variant="h5" margin="12px 0">
          Property Details
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl size="small" fullWidth>
              <InputLabel>Property Type</InputLabel>
              <Select name="propertyType" value={formData.propertyType} onChange={handleChange}>
                <MenuItem value="House">House</MenuItem>
                <MenuItem value="Apartment">Apartment</MenuItem>
                <MenuItem value="Land">Land</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl size="small" fullWidth>
              <InputLabel>Type</InputLabel>
              <Select name="type" value={formData.type} onChange={handleChange}>
                <MenuItem value="Commercial">Commercial</MenuItem>
                <MenuItem value="Residential">Residential</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl size="small" fullWidth>
              <InputLabel>Facing</InputLabel>
              <Select name="facing" value={formData.facing} onChange={handleChange}>
                <MenuItem value="North">North</MenuItem>
                <MenuItem value="South">South</MenuItem>
                <MenuItem value="East">East</MenuItem>
                <MenuItem value="West">West</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl size="small" fullWidth>
              <InputLabel>Urgency</InputLabel>
              <Select name="urgency" value={formData.urgency} onChange={handleChange}>
                <MenuItem value="Very Urgent">Very Urgent</MenuItem>
                <MenuItem value="Not Urgent">Not Urgent</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Typography variant="h5" margin="12px 0">
          Price Range
        </Typography>

        <Grid container spacing={2} justifyContent="center">
          <Grid item>
            <TextField
              size="small"
              name="priceFrom"
              value={formData.priceFrom}
              onChange={handleChange}
              variant="standard"
              label="From"
            />
          </Grid>
          <Grid item>
            <TextField
              size="small"
              name="priceTo"
              value={formData.priceTo}
              onChange={handleChange}
              variant="standard"
              label="To"
            />
          </Grid>
        </Grid>

        <Box sx={{ margin: "20px 0" }}>
          <TextField
            id="description"
            name="description"
            multiline
            rows={3}
            placeholder="Enter property details including floor number, BHK, amenities, nearby landmarks, and any other relevant information."
            sx={{ backgroundColor: "#f9f9f9", borderRadius: "10px", width: "100%" }}
            value={formData.description}
            onChange={handleChange}
          />
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button type="submit" variant="contained" color="primary">
            Submit Request
          </Button>
        </Box>
      </form>
    </Box>
  );
}

export default PropertyRequest;
