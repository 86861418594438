import React, { useState } from "react";
import DetailForm from "./detail_form";
import PropertyRequest from "./house_form";
import { Dialog, Box, Typography } from "@mui/material";
import axios from "axios";

function FormContainer() {
  const [openDetailForm, setOpenDetailForm] = useState(true);
  const [openRequestDialog, setOpenRequestDialog] = useState(false);
  const [detailData, setDetailData] = useState({});

  // Handle the submission of DetailForm
  const handleDetailFormSubmit = (data) => {
    setDetailData(data);
    setOpenDetailForm(false); // Close DetailForm and its header after submission
    setOpenRequestDialog(true); // Open PropertyRequest form
  };

  // Handle the submission of PropertyRequest with merged data
  const handlePropertyRequestSubmit = async (propertyData) => {
    const finalData = { 
      ...detailData, 
      p_location: [
        propertyData.province,
        propertyData.district,
        propertyData.municipality,
        propertyData.ward,
        propertyData.landmark,
      ],
      propertyType: propertyData.propertyType,
      Facing: propertyData.facing,
      Urgency: propertyData.urgency,
      priceFrom: propertyData.priceFrom,
      priceTo: propertyData.priceTo,
      description: propertyData.description,
      type: propertyData.type
    };

    try {
      const response = await axios.post("http://localhost:8001/byd/propertyRequest", finalData);
      if (response.status === 200) {
        alert("Form submitted successfully!");
        setOpenRequestDialog(false); // Close PropertyRequest form
      } else {
        alert("Error submitting form.");
      }
    } catch (error) {
      console.error("Submission error:", error);
    }
  };

  return (
    <Box>
      {openDetailForm && (
        <Box>
          <Typography variant="h4" sx={{ textAlign: "center",my: 2 }}>
            Fill in Your Details
          </Typography>
          <DetailForm onSubmit={handleDetailFormSubmit} />
        </Box>
      )}

      {/* Second Form (Property Request) inside Dialog */}
      <Dialog open={openRequestDialog} onClose={() => setOpenRequestDialog(false)} maxWidth="lg" fullWidth
        BackdropProps={{
          sx: { backgroundColor:"transparent" }
        }}
        >
        <Box sx={{ p: 3 }}>
          <Typography variant="h4" sx={{ textAlign: "center", mb: 2 }}>
            Provide Property Details
          </Typography>
          <PropertyRequest onSubmit={handlePropertyRequestSubmit} />
        </Box>
      </Dialog>
    </Box>
  );
}

export default FormContainer;
