import React, { useState } from "react";
import { Grid, Container, Typography } from "@mui/material";
import BlogCard from "./blog_card";
import blogsData from "./blogs.data";
import BlogDetail from "./blogs_detail.component";

const BlogList = () => {
  const [selectedBlog, setSelectedBlog] = useState(null);

  return (
    <Container sx={{ mt: 5 }}>
        <Typography variant="h5" fontWeight="700" gutterBottom align="center"sx={{  fontSize: {xs:"24px",sm:"28px",md:"38px",lg:"48px"},
                        color: "rgba(0,0,0,1)",
                        textTransform: "uppercase" }}>
        Blogs 
      </Typography>
      {selectedBlog ? (
        <BlogDetail blog={selectedBlog} onBack={() => setSelectedBlog(null)} />
      ) : (
        <Grid container spacing={3} justifyContent="center" alignItems="center"
        
        >


          {blogsData.map((blog, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <BlogCard blog={blog} onClick={() => setSelectedBlog(blog)} />
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  );
};

export default BlogList;
