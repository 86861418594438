


import React from "react";
import { Box, Typography, Button, Container } from "@mui/material";

const BlogDetail = ({ blog, onBack }) => {
  return (
    <Container sx={{
       backgroundColor: "#f5f5f5",
        mt: 4, p: 3, borderRadius: 2, 
        boxShadow: 3,
        maxWidth: "800px", 
        width: "70%", 
        }}>
      <Typography variant="h4" fontWeight="bold" textAlign="center" sx={{ mb: 1 }}>
        {blog.title}
      </Typography>
      <Typography variant="subtitle1" textAlign="center" color="textSecondary" fontStyle="italic">
        By {blog.author} | {blog.date}
      </Typography>
      <Box component="img" src={blog.image} alt={blog.title} sx={{ width: "100%",   maxWidth: "700px",mt: 2, borderRadius: 2 ,display:'block',marginRight:'auto',marginLeft:'auto'}} />
      <Typography variant="body1" sx={{ mt: 2 }}>{blog.content}</Typography>
      <Button sx={{ mt: 3 }} variant="outlined" onClick={onBack}>Back to Blogs</Button>
    </Container>
  );
};

export default BlogDetail;
