import * as React from 'react';
import {Grid ,Typography,Box,Container  }  from '@mui/material';

const message = [
    {
      id:1,
      image: "https://wallpapers.com/images/featured/samantha-hd-3nzxx6n7zr2i7jsc.jpg",
      name: "ABC",
      post: "Manager",
      message:"I wanted to extend a warm welcome and let you know that we are here to assist you in finding the perfect property, whether it’s a new home, an investment opportunity, or selling your current property. We are committed to providing you with excellent service and ensuring that your real estate experience is as smooth and successful as possible. "
    }
  ];

export default function MessageContent() {
  return (
     <Container
          maxWidth={false}
          sx={{
            backgroundColor: "primary.fifth",
            padding: "0",
          }}
        >
          <Container
            sx={{
              display: "flex",
              flexDirection: { xs: "column" },
              justifyContent: "center",
              alignItems: "center",
              height: "auto",
              // backgroundColor:"red",
              padding: { xs: "10px 10px", sm: "30px 10px", lg: "30px 10px 20px 10px" },
            }}
          > {/* Ensure more width */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          gap: 2,
          padding: {sx:"20px 0",sm:"20px 0",md:"20px 10px"},
          alignItems: "stretch",
          width: "100%", // Ensure full width
        }}
      >
        {message.map((item, index) => (
             <Grid container spacing={2} alignItems="center" key={item.id} sx={{ mb: 4 }}>
             {/* Left Side - Project Image */}
             <Grid item xs={12} md={6} lg={6}>
                 <Box
                     component="img"
                     src={item.image}
                     alt={item.name}
                     sx={{ width: '100%', height: 'auto', borderRadius: 2 }}
                 />
             </Grid>

             {/* Right Side - item Details */}
             <Grid item xs={12} md={6} lg={6}>
                 <Typography gutterBottom
                variant="h5"
                fontFamily="Roboto-Serif"
                component="div"
                sx={{
                  fontSize: { xs: "16px", sm: "22px" },
                  fontWeight: "400",
                  marginBottom: 0
                }}>
                     {item.name}
                 </Typography>
                 <Typography variant="subtitle2"
                fontFamily="Roboto-Serif"
                sx={{
                  fontSize: { xs: "12px", sm: "14px" },
                  fontWeight: "500",
                  fontStyle: "italic",
                  color: "rgba(0,0,0,0.6)",
                  marginBottom: "8px",
                }}>
                     {item.post}
                 </Typography>
                 <Typography variant="body1" sx={{ mt: 2, textAlign: 'justify' }}>
                 {item.message}
                  </Typography>

                 {/* Description */}
                 
             </Grid>
         </Grid>
        //   <Card key={index} sx={{ minWidth: "280px", maxWidth: "320px", flex: "1 1 auto" }}>
        //     <CardMedia sx={{ height: 140 }} image={item.image} title={item.name} />
        //     <CardContent>
        //       <Typography
        //         gutterBottom
        //         variant="h5"
        //         fontFamily="Roboto-Serif"
        //         component="div"
        //         sx={{
        //           fontSize: { xs: "16px", sm: "22px" },
        //           fontWeight: "400",
        //           marginBottom: 0
        //         }}
        //       >
        //         {item.name}
        //       </Typography>
        //       <Typography
        //         variant="subtitle2"
        //         fontFamily="Roboto-Serif"
        //         sx={{
        //           fontSize: { xs: "12px", sm: "14px" },
        //           fontWeight: "500",
        //           fontStyle: "italic",
        //           color: "rgba(0,0,0,0.6)",
        //           marginBottom: "8px",
        //         }}
        //       >
        //         {item.post}
        //       </Typography>
        //       <Typography
        //         variant="body2"
        //         fontFamily="AppleMyungjo"
        //         sx={{
        //           fontSize: { xs: "12px", sm: "14px" },
        //           textAlign: "justify",
        //           color: "rgba(0,0,0,0.7)",
        //         }}
        //       >
        //         {item.message}
        //       </Typography>
        //     </CardContent>
        //   </Card>
        ))}
      </Box>
    </Container>
    </Container>
  );
}
